import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import history from './history';
import Routes from './router';
import ReactGA from 'react-ga';

ReactGA.initialize("UA-165277307-1");

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  };

  render(){
    return (
      <Router history={history}>
        <Routes />
      </Router>
    );
  }
}

export default App;