import FollowUpActions from '../data/followUpActions';

export default function(id){
    var actionsList = [];
    
    var followUp = FollowUpActions.find(a => a.id === id);
    if(!followUp && !followUp.actions){
        return null;
    }

    followUp.actions.forEach((action) => {
        for(var i = 0; i < action.frequency; i++){
            actionsList.push(action.text);
        }
    });

    var random = Math.floor(Math.random() * actionsList.length);
    return actionsList[random];
}