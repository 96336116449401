import GetFollowUpAction from '../helpers/getFollowUpAction';

export default function(question){
    return {
        "id": question.id,
        "title": question.title,
        "description": question.description,
        "subText": question.subText,
        "type": question.type,
        "continueBtn": question.continueBtn,
        "showFollowUpAction": false,
        "followUpAction": GetFollowUpAction("1")
    };
}