import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Landing from './pages/landing/landing';
import Game from './pages/game/game';
import GameOver from './pages/gameOver/gameOver';
import Setup from './pages/setup/setup';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Landing} />
      <Route path="/setup" component={Setup} />
      <Route path="/game/:gameId" component={Game} />      
      <Route path="/gameOver" component={GameOver} />
    </Switch>
  );
}