import QuestionViewModel from '../models/questionViewModel';
import Questions from '../data/questions.json';

export default function(adultFilter){
    var filteredQuestions = Questions.filter((question) => {
        if(adultFilter === 'clean'){
            return question.adultType === 'clean';
        }
        if(adultFilter === 'moderate'){
            return question.adultType === 'clean' || question.adultType === 'moderate';
        }
        return true;
    });

    console.log(filteredQuestions.length);
    var limit = filteredQuestions.length;
    if(limit > 60){
        limit = 60; // Temporary Fix For Lack of Questions
    }

    var unusedQuestions = filteredQuestions;
    var gameQuestions = [];

    for(var i = 0; i < limit; i++){
        var random = Math.floor(Math.random() * unusedQuestions.length);
        var question = QuestionViewModel(unusedQuestions[random]);
        gameQuestions.push(question);
        unusedQuestions.splice(random, 1);
    }

    return gameQuestions;
}