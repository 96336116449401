import React, { Component } from 'react';
import './gameOver.css';

//set up App class with constructors
class GameOver extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render(){
    return (
    <div id="content-holder-parent">
      <div id="content-holder">
        <div className="container mt-5 pt-5">
            <div className="text-center">
              <h3>You survived the power hour!</h3>
              <h4>If you enjoyed playing, help others find us by sharing on Facebook!</h4>

              <p class="py-5">
                <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fgrouppartygames.com">
                  <svg class="facebook" viewBox="0 0 20 20">
							      <path fill="none" d="M11.344,5.71c0-0.73,0.074-1.122,1.199-1.122h1.502V1.871h-2.404c-2.886,0-3.903,1.36-3.903,3.646v1.765h-1.8V10h1.8v8.128h3.601V10h2.403l0.32-2.718h-2.724L11.344,5.71z"></path>
						      </svg>                
                </a>
              </p>
              
              <a className="btn btn-lg" href="/setup">Round 2? (You're Insane)</a>
            </div>
        </div>
      </div>
      <div className="bg-circle-1 bg-circle"></div>
      <div className="bg-circle-2 bg-circle"></div>
      <div className="bg-circle-3 bg-circle"></div>
      <div className="bg-circle-4 bg-circle"></div>
    </div>
    );
  }
}

export default GameOver;
