import React, { Component } from 'react';
import axios from 'axios';
import GenerateId from '../../helpers/generateId';
import GetGameQuestions from '../../helpers/getGameQuestions';
import './setup.css';
import Loader from '../../images/tail-spin.svg';

//set up App class with constructors
class Setup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      gameLink: "",
      copied: false,
      adultFilter: "moderate"  // clean, moderate, adult
    };
  }

  generateGameLink(){
    this.setState({ isLoading: true });
    var baseUrl = window.location.origin;
    var gameId = GenerateId(10);

    var gameLink = `${baseUrl}/game/${gameId}`;

    var gameQuestions = GetGameQuestions(this.state.adultFilter);
    var gameConfig = {
      startedAt: null,
      questions: gameQuestions
    };

    // make axios request
    axios.post(`/create-game?gameId=${gameId}`, gameConfig)
      .then(res => {
        if(res.status === 200){
          this.setState({
            isLoading: false,
            gameLink: gameLink,
            gameId: gameId
          });
        }
      })
  }

  copyToClipboard(){
    const el = this.gameLinkInput;
    el.select();
    document.execCommand("copy");
    this.setState({copied: true});
  }

  setAdultFilter(e){
    const adultFilter = e.target.value;
    this.setState({
      adultFilter
    });
  }

  render(){
    return (
    <div id="content-holder-parent">
      <div id="content-holder">
        <div className="container mt-5">
          { this.state.gameLink ?
            <div className="text-center pt-5 mt-5">
              <div className="mt-5">
                <h3>Invite your friends!</h3>
                <h5>Share the link below with your friends.</h5>
              </div>
              <div className="input-group game-link-container mt-5">
                <input type="text" value={this.state.gameLink} className="form-control game-link-input" 
                  ref={(gameLinkInput) => this.gameLinkInput = gameLinkInput}
                  onClick={() => this.copyToClipboard()} />
                  { this.state.copied ?
                    <span className="input-group-text game-link-button-text-copied mr-3">Copied!</span> 
                    :
                    <button className="input-group-append game-link-button" onClick={() => this.copyToClipboard()}>
                      <span className="input-group-text game-link-button-text">Copy Link</span>
                    </button>
                  }
              </div>
              <div className="mt-5">
                  <a href={`/game/${this.state.gameId}`} className="btn btn-primary">Enter Game</a>
              </div>
            </div>
            :
            <div className="text-center">
              <h3>Personalize Your Game</h3>
              <div className="btn-group btn-group-toggle my-4" data-toggle="buttons">
                <label className="btn btn-secondary active">
                  <input type="radio" name="location-options" value="remote" defaultChecked/> Remote
                </label>
                <label className="btn btn-secondary">
                  <input type="radio" name="location-options" value="inperson"/> In Person 
                </label>
              </div>
              <br />
              <div className="btn-group btn-group-toggle my-4" data-toggle="buttons">
                <label className="btn btn-secondary">
                  <input type="radio" name="adult-options" onClick={this.setAdultFilter.bind(this)} value="clean" /> Clean
                </label>
                <label className="btn btn-secondary active">
                  <input type="radio" name="adult-options" onClick={this.setAdultFilter.bind(this)} value="moderate" defaultChecked/> Moderate
                </label>
                <label className="btn btn-secondary">
                  <input type="radio" name="adult-options" onClick={this.setAdultFilter.bind(this)} value="adult"/> Adult 
                </label>
              </div>
              <br />
              <div className="btn-group btn-group-toggle my-4" data-toggle="buttons">
                <label className="btn btn-secondary active disabled">
                  <input type="radio" name="music-options" value="on" defaultChecked/> No Music
                </label>
                <label className="btn btn-secondary disabled">
                  <input type="radio" name="music-options" value="off"/> Music 
                </label>
              </div>
              <br />
              <button className="btn btn-lg mt-5" onClick={this.generateGameLink.bind(this)}>
                { this.state.isLoading ?
                  <img src={Loader} alt="loader" /> :
                  "Get game link"
                }
              </button>
            </div>
          }
        </div>
      </div>
      <div className="bg-circle-1 bg-circle"></div>
      <div className="bg-circle-2 bg-circle"></div>
      <div className="bg-circle-3 bg-circle"></div>
      <div className="bg-circle-4 bg-circle"></div>
    </div>
    );
  }
}

export default Setup;
