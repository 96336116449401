import React, { Component } from 'react';
import './game.css';
import axios from 'axios';
import Loader from '../../images/tail-spin.svg';
import { useParams } from 'react-router-dom';

//set up App class with constructors
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentState: "waiting",  // waiting, playing
      secondsElapsed: 0,
      currentQuestion: {},
      showRatings: true,
      isLoading: false,
    };

    this.gameQuestions = [];
    this.gameId = "";
  }

  componentDidMount(){
    this.gameId = this.props.match.params.gameId;

    console.log(this.gameId);

    var timer = setInterval(() => {
      axios.get(`/get-game?gameId=${this.gameId}`)
        .then(res => {
          if(res.status === 200){
            if(res.data && res.data.startedAt != null){
              // Load data from server
              this.gameQuestions = res.data.questions;
              this.startedAt = new Date(res.data.startedAt);

              // Remove questions past current timer
              var secondsElapsed = Math.floor((new Date() - this.startedAt) /1000);
              var minutesElapsed = Math.floor((secondsElapsed) / 60);
              this.gameQuestions.splice(0, minutesElapsed);

              // Start Game
              this.beginGame();
              clearInterval(timer);
            }
          }
        })
    }, 1000);
  }

  beginGame(){
    // setup new game
    this.setNewQuestion();

    var timer = setInterval(() => {
      this.mainGameLoop();
    }, 1000);

    this.setState({
        isLoading: false,
        timer,
        secondsElapsed: Math.floor((new Date() - this.startedAt) /1000),
        currentState: "playing"
    });
  }

  // the main game loop which will be executed every 1s
  mainGameLoop(){
    var secondsElapsed = this.state.secondsElapsed + 1;

      if(secondsElapsed >= 3600){
        // one hour has passed - game's over
        clearInterval(this.state.timer);

        window.location.href = "/gameOver";
        return;
      }
      if(secondsElapsed % 60 === 0){
        // new minute, new question
        this.setNewQuestion();
      }

      this.setState({
        secondsElapsed
      });
  }

  setNewQuestion(){
    var newQuestion = this.gameQuestions[0];

    this.gameQuestions.splice(0, 1);

    this.setState({
      currentQuestion: newQuestion,
      showRatings: true
    });
  }

  thumbsup(){
    this.setState({ showRatings: false });
    axios.post("/thumbs-up?questionId=" + this.state.currentQuestion.id)
      .then(res => {
        if(res.status === 200){
          // Clear the thumbs?
        }
      })
  }
  
  thumbsdown(){
    this.setState({ showRatings: false });
    axios.post("/thumbs-down?questionId=" + this.state.currentQuestion.id)
      .then(res => {
        if(res.status === 200){
          // Clear the thumbs?
        }
      })
  }

  handleBeginClicked(){
    // make axios call
    this.setState({isLoading:true});
    axios.post(`/start-game?gameId=${this.gameId}`)
      .then(res => {
        if(res.status !== 200){
          this.setState({isLoading:false});
        }
      })
      .catch(res => {
        this.setState({isLoading:false});
      })
  }

  renderCurrentQuestion(){
    var showFollowUpAction = () => {
      var currentQuestion = Object.assign({}, this.state.currentQuestion);
      currentQuestion.showFollowUpAction = true;
      
      this.setState({
        currentQuestion
      });
    };

    return(
      <div className="question-container pt-5">
        { this.state.currentQuestion.title &&
          <h3 className="question-title">{this.state.currentQuestion.title}</h3>
        }
        { this.state.currentQuestion.description &&
          <p className="question-description">{this.state.currentQuestion.description}</p>
        }
        { this.state.currentQuestion.subText &&
          <p className="question-sub-text mt-2">{this.state.currentQuestion.subText}</p>
        }
        { this.state.currentQuestion.continueBtn &&
          <button onClick={showFollowUpAction} className="btn btn-lg mt-5">{this.state.currentQuestion.continueBtn}</button>
        }
        { this.state.showRatings && 
            <div className="rating-container pt-4 row">
                <div className="col-3"></div>
                <div className="col-3">
                  <svg className="thumbs-up" onClick={this.thumbsup.bind(this)} viewBox="0 0 50 50">
                    <path d="M40 23.2c0-2.1-1.7-3.2-4-3.2h-6.7c.5-1.8.7-3.5.7-5 0-5.8-1.6-7-3-7-.9 0-1.6.1-2.5.6-.3.2-.4.4-.5.7l-1 5.4c-1.1 2.8-3.8 5.3-6 7V36c.8 0 1.6.4 2.6.9 1.1.5 2.2 1.1 3.4 1.1h9.5c2 0 3.5-1.6 3.5-3 0-.3 0-.5-.1-.7 1.2-.5 2.1-1.5 2.1-2.8 0-.6-.1-1.1-.3-1.6.8-.5 1.5-1.4 1.5-2.4 0-.6-.3-1.2-.6-1.7.8-.6 1.4-1.6 1.4-2.6zm-2.1 0c0 1.3-1.3 1.4-1.5 2-.2.7.8.9.8 2.1 0 1.2-1.5 1.2-1.7 1.9-.2.8.5 1 .5 2.2v.2c-.2 1-1.7 1.1-2 1.5-.3.5 0 .7 0 1.8 0 .6-.7 1-1.5 1H23c-.8 0-1.6-.4-2.6-.9-.8-.4-1.6-.8-2.4-1V23.5c2.5-1.9 5.7-4.7 6.9-8.2v-.2l.9-5c.4-.1.7-.1 1.2-.1.2 0 1 1.2 1 5 0 1.5-.3 3.1-.8 5H27c-.6 0-1 .4-1 1s.4 1 1 1h9c1 0 1.9.5 1.9 1.2z"></path>
                    <path d="M16 38h-6c-1.1 0-2-.9-2-2V22c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2zm-6-16v14h6V22h-6z"></path>
                  </svg>
                  <div class="thumbs-text">Like question</div>
                </div>
                <div className="col-3">
                  <svg className="thumbs-down" onClick={this.thumbsdown.bind(this)} viewBox="0 0 50 50">
                    <path d="M40 23.2c0-2.1-1.7-3.2-4-3.2h-6.7c.5-1.8.7-3.5.7-5 0-5.8-1.6-7-3-7-.9 0-1.6.1-2.5.6-.3.2-.4.4-.5.7l-1 5.4c-1.1 2.8-3.8 5.3-6 7V36c.8 0 1.6.4 2.6.9 1.1.5 2.2 1.1 3.4 1.1h9.5c2 0 3.5-1.6 3.5-3 0-.3 0-.5-.1-.7 1.2-.5 2.1-1.5 2.1-2.8 0-.6-.1-1.1-.3-1.6.8-.5 1.5-1.4 1.5-2.4 0-.6-.3-1.2-.6-1.7.8-.6 1.4-1.6 1.4-2.6zm-2.1 0c0 1.3-1.3 1.4-1.5 2-.2.7.8.9.8 2.1 0 1.2-1.5 1.2-1.7 1.9-.2.8.5 1 .5 2.2v.2c-.2 1-1.7 1.1-2 1.5-.3.5 0 .7 0 1.8 0 .6-.7 1-1.5 1H23c-.8 0-1.6-.4-2.6-.9-.8-.4-1.6-.8-2.4-1V23.5c2.5-1.9 5.7-4.7 6.9-8.2v-.2l.9-5c.4-.1.7-.1 1.2-.1.2 0 1 1.2 1 5 0 1.5-.3 3.1-.8 5H27c-.6 0-1 .4-1 1s.4 1 1 1h9c1 0 1.9.5 1.9 1.2z"></path>
                    <path d="M16 38h-6c-1.1 0-2-.9-2-2V22c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2zm-6-16v14h6V22h-6z"></path>
                  </svg>
                  <div class="thumbs-text">Dislike question</div>
                </div>
                <div className="col-3"></div>
            </div>
        }
      </div>
    );
  }

  renderFollowUpAction(){
    var secondsLeft = 60 - (this.state.secondsElapsed - ((Math.floor(this.state.secondsElapsed / 60)) * 60));
    var actionText = this.state.currentQuestion.followUpAction;

    if(actionText.includes("{seconds}")){
      actionText = actionText.replace("{seconds}", secondsLeft.toString().padStart(2, "0"));
    }
    return(
      <div className="follow-up-container mt-5 pt-5">
          <h3 className="question-title mt-5">{actionText}</h3>
      </div>
    );
  }

  render(){
    var secondsElapsed = (this.state.secondsElapsed - ((Math.floor(this.state.secondsElapsed / 60)) * 60)).toString().padStart(2, "0");
    var minutesElpased = Math.floor(this.state.secondsElapsed / 60);
    var percentOfMinute = (secondsElapsed/60) * 100;

    return (
    <div id="content-holder-parent">
      <div id="content-holder">
        <div className="container">
            {
                this.state.currentState === "waiting" &&
                <div className="text-center mt-5 pt-5">
                    <h3>Prepare yourselves!</h3>
                    <p>Wait to start until everyone in your party is ready</p>
                    <button className="btn btn-lg mt-5" onClick={this.handleBeginClicked.bind(this)}>
                      { this.state.isLoading ?
                        <img src={Loader} alt="Loading..." />
                        :
                        "Begin the Hour of Power"
                      }
                    </button>
                </div>
            }
            { this.state.currentState === "playing" && this.state.currentQuestion &&
                <div className="game-container text-center">
                    <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{width: percentOfMinute+"%"}} aria-valuenow={percentOfMinute} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div>
                    <span className="rounds">Round {minutesElpased + 1}</span>
                    <span className="timer">{minutesElpased}:{secondsElapsed}</span>
                    </div>
                    {!this.state.currentQuestion.showFollowUpAction ? this.renderCurrentQuestion() : this.renderFollowUpAction()}
                </div>
            }
        </div>
      </div>
      <div className="bg-circle-1 bg-circle"></div>
      <div className="bg-circle-2 bg-circle"></div>
      <div className="bg-circle-3 bg-circle"></div>
      <div className="bg-circle-4 bg-circle"></div>
    </div>
    );
  }
}

export default App;
