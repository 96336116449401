import React, { Component } from 'react';
import './landing.css';

//set up App class with constructors
class Landing extends Component {
  
    constructor(props) {
        super(props);
        this.state = {};
    }

    render(){

        return (
            <div id="content-holder-parent">
                <div id="content-holder">
                    <div className="container">
                        <div className="text-center">
                            <h1 id="homepage-header">Remote Power Hour</h1>
                            <h3 class="d-none d-sm-block">Still Getting Rowdy</h3>

                            <a className="btn btn-lg mt-5" href="/setup">Setup Game</a>
                        </div>
                    </div>
                </div>
                <div className="bg-circle-1 bg-circle"></div>
                <div className="bg-circle-2 bg-circle"></div>
                <div className="bg-circle-3 bg-circle"></div>
                <div className="bg-circle-4 bg-circle"></div>
            </div>
        );
    }
}

export default Landing;
